
import { defineComponent } from "vue";
import store from "@/store"
import { mapState } from "vuex";
export default defineComponent({
  created() {
    store.dispatch("sidemenu/setCollapsed");
    let user = store.getters["session/getUser"];
    if (!user) {
      this.text = "This page cannot be accessed until you have logged in",
      this.subtext = "Please refer to your most recent invoice or contact your sales representative for the proper URL."
    }
  },
  computed: {
    ...mapState(["theme", "session"]),
  },
  data() {
    return {
      text: "PAGE NOT FOUND",
      subtext: "OOPS! Looks like you were left on read."
    }
  }
});
