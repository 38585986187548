import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid p-8" }
const _hoisted_2 = { class: "col-12 flex justify-content-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-12 flex justify-content-center" }
const _hoisted_5 = { class: "my-0" }
const _hoisted_6 = { class: "col-12 flex justify-content-center pt-0 mt-0" }
const _hoisted_7 = { class: "m-0 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.theme.logo,
        style: {"height":"1.5rem"},
        class: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h1", _hoisted_5, _toDisplayString(this.text), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(this.subtext), 1)
    ])
  ]))
}